@import '@styles/sass/mixins/rem';

.paragraphAndImage {
  &__remark {
    * {
      font-size: rem(14) !important;
      line-height: rem(20) !important;
    }
  }
}
