@import 'styles/sass/mixins/rem';

.image {
  img {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }

  img:hover {
    transform: scale(1.025);
  }
}

.image__nolink {
  img {
    transform: scale(1) !important;
  }
}

//image position
.under_the_image {
  .image {
    width: 100%;
    margin-bottom: rem(20);
  }

  .image.circle {
    margin-bottom: rem(10);
  }

  .paragraphFlex {
    flex-direction: column;
  }
}

.before_the_image {
  .image {
    margin-left: rem(15);
  }

  .paragraphFlex {
    flex-direction: row-reverse;
  }
}

.next_to_the_image {
  .image {
    margin-right: rem(15);
  }

  .paragraphFlex {
    flex-direction: row;
  }
}

//image style
.circle img {
  border-radius: 50%;
}

.square,
.rectangle {
  img {
    border-radius: rem(16);
  }
}

//button
.paragraphButton {
  text-align: center;

  button {
    min-width: rem(160);
    margin: 0 auto;
  }
}

.button_disclaimer p {
  font-size: rem(14);
  line-height: rem(18);
}

.paragraph_title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-size: rem(18);
    font-weight: bold;
    line-height: rem(24);
  }
}

.paragraph_text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-size: rem(16);
    font-weight: normal;
    line-height: rem(22);
  }
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none !important;
    background-image: url('/public/icons/heart-orange.png');
    background-repeat: no-repeat;
    background-position: rem(0) rem(5);
    padding-left: rem(22);
    margin-bottom: rem(5);
  }
}
